.payroll-menu-scroll {
  max-height: calc(100vh - 95px);
  overflow-y: auto;
  overflow-x: hidden;
}
.payroll-menu {
  border-right: transparent !important;
}
.payroll-menu li {
  padding: 10px 16px !important;
  height: auto !important;
  line-height: 20px !important;
}
.payroll-menu span {
  white-space: normal !important;
  font-weight: 400 !important;
}
.payroll-menu .ant-menu-item::after {
  border-right: transparent !important;
}
