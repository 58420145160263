.loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.loading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loading .ant-spin {
  color: #3e79f7;
}
.custom-full-modal {
  top: 0px !important;
  padding: 20px 0px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.custom-modal-wrap {
  display: flex;
}
.custom-full-modal .ant-modal-body {
  max-height: calc(100vh - 40px);
  overflow-y: auto;
}
.swtich-item .ant-form-item-row {
  flex-direction: row !important;
  align-items: baseline !important;
}

.form-disabled
  .ant-select-disabled.ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.ant-select-disabled.ant-select-multiple .ant-select-selection-item {
  color: #727a81 !important;
}

.schedule-quiz__square {
  width: 40px;
  height: 40px;
  border-radius: 6px;
  background: color(srgb 0.9652 0.9787 0.995);
  border: 1px solid color(srgb 0.9022 0.9222 0.9435);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.schedule-quiz__square:hover {
  background: color(srgb 0.9022 0.9222 0.9435);
}

.shadow-blue {
  box-shadow: 0px 0px 10px 0px rgba(0, 155, 100, 0.5);
}
